import { SlotsPlaceholder } from '@wix/widget-plugins-ooi';
import React from 'react';
import { AvailabilityPluginDataHooks } from './constants';
import { usePreset } from '../../Hooks/usePreset';
import { AVAILABILITY_PLUGIN_SLOT_ID } from '../../../../constants/constants';
import { classes } from './AvailabilityPlugin.st.css';

export interface AvailabilityPluginProps {}

export const AvailabilityPlugin: React.FC<availabilitypluginprops> = () => {
  const { isCalendarPage, isCalendarWidget } = usePreset();

  const isCalendar = isCalendarPage || isCalendarWidget;

  return isCalendar ? (
    <div data-hook="{AvailabilityPluginDataHooks.ROOT}" className="{classes.root}">
      <slotsplaceholder slotId="{AVAILABILITY_PLUGIN_SLOT_ID}"></slotsplaceholder>
    </div>
  ): لاغية;
};
</availabilitypluginprops>